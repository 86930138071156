var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',[_c('v-card',{staticClass:"mt-4 mx-auto",attrs:{"max-width":"800px","loading":_vm.loading,"disabled":_vm.loading}},[_c('v-card-text',[_c('v-card-title',{domProps:{"textContent":_vm._s(_vm.$t('Addon'))}}),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_vm._l((_vm.parent ? _vm.resolveParents(_vm.parent) : _vm.item.parent ? _vm.resolveParents(_vm.item.parent) : []),function(item){return [(item.title)?_c('a',{key:item.title,attrs:{"href":_vm.$router.resolve({
                  name: 'AddonUpdate',
                  params: { id: item['@id'] }
                }).href},on:{"click":(e) => {
                  e.preventDefault();
                  _vm.$router.push({
                    name: 'AddonUpdate',
                    params: { id: item['@id'] }
                  });
                }}},[_vm._v(_vm._s(item.title)+"/")]):_vm._e()]}),_vm._v(" "+_vm._s(_vm.item.title)+" ")],2)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"outlined":"","error-messages":_vm.titleErrors,"label":_vm.$t('title'),"required":""},on:{"blur":function($event){return _vm.$v.item.title.$touch()},"input":function($event){return _vm.$v.item.title.$touch()}},model:{value:(_vm.item.title),callback:function ($$v) {_vm.$set(_vm.item, "title", $$v)},expression:"item.title"}})],1),_c('v-col',[_c('h3',[_vm._v(_vm._s(_vm.$t('description')))]),_c('InputEditor',{attrs:{"error-messages":_vm.descriptionErrors},model:{value:(_vm.item.description),callback:function ($$v) {_vm.$set(_vm.item, "description", $$v)},expression:"item.description"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"error-messages":_vm.priceErrors,"label":_vm.$t('price'),"prefix":"€"},on:{"blur":function($event){return _vm.$v.item.price.$touch()},"input":function($event){return _vm.$v.item.price.$touch()}},model:{value:(_vm.item.price),callback:function ($$v) {_vm.$set(_vm.item, "price", _vm._n($$v))},expression:"item.price"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"error-messages":_vm.sortOrderErrors,"label":_vm.$t('sortOrder'),"hint":"kleiner Wert wird zuerst angezeigt. Tipp: 100er Schritte"},on:{"blur":function($event){return _vm.$v.item.sortOrder.$touch()},"input":function($event){return _vm.$v.item.sortOrder.$touch()}},model:{value:(_vm.item.sortOrder),callback:function ($$v) {_vm.$set(_vm.item, "sortOrder", _vm._n($$v))},expression:"item.sortOrder"}})],1)],1),(_vm.item['@id'])?void 0:_vm._e(),(this.refForm === 'updateForm' && _vm.item.parent === null)?_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":"primary","to":{ name: 'AddonCreate', params: { parent: _vm.item['@id'] } }}},[_vm._v(" "+_vm._s(_vm.$t('CreateChildAddon'))+" ")])],1)],1):_vm._e()],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }