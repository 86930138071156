<template>
  <v-form>
    <v-card
      max-width="800px"
      class="mt-4 mx-auto"
      :loading="loading"
      :disabled="loading"
    >
      <v-card-text>
        <v-card-title v-text="$t('Addon')" />
        <v-container fluid>
          <v-row>
            <v-col>
              <template
                v-for="item in parent ? resolveParents(parent) : item.parent ? resolveParents(item.parent) : []">
                <a
                  v-if="item.title"
                  :key="item.title"
                  :href="$router.resolve({
                    name: 'AddonUpdate',
                    params: { id: item['@id'] }
                  }).href
                    "
                  @click="(e) => {
                    e.preventDefault();
                    $router.push({
                      name: 'AddonUpdate',
                      params: { id: item['@id'] }
                    });
                  }
                    "
                >{{ item.title }}/</a>
              </template>
              {{ item.title }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                outlined
                :error-messages="titleErrors"
                :label="$t('title')"
                @blur="$v.item.title.$touch()"
                @input="$v.item.title.$touch()"
                required
                v-model="item.title"
              />
            </v-col>
            <v-col>
              <h3>{{ $t('description') }}</h3>
              <InputEditor
                v-model="item.description"
                :error-messages="descriptionErrors"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                :error-messages="priceErrors"
                :label="$t('price')"
                @blur="$v.item.price.$touch()"
                @input="$v.item.price.$touch()"
                v-model.number="item.price"
                prefix="€"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                :error-messages="sortOrderErrors"
                :label="$t('sortOrder')"
                @blur="$v.item.sortOrder.$touch()"
                @input="$v.item.sortOrder.$touch()"
                v-model.number="item.sortOrder"
                hint="kleiner Wert wird zuerst angezeigt. Tipp: 100er Schritte"
              />
            </v-col>
          </v-row>
          <template v-if="item['@id']"> </template>
          <v-row v-if="this.refForm === 'updateForm' && item.parent === null">
            <v-col>
              <v-btn
                color="primary"
                :to="{ name: 'AddonCreate', params: { parent: item['@id'] } }"
              >
                {{ $t('CreateChildAddon') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
  import has from 'lodash/has';
  import { validationMixin } from 'vuelidate';
  import { required } from 'vuelidate/lib/validators';
  import InputEditor from '@/components/InputEditor';
  import { mapActions } from 'vuex';
  import NotificationMixin from '../../mixins/NotificationMixin';
  import AddonMixin from '../../mixins/AddonMixin';

  export default {
    name: 'AddonForm',
    mixins: [validationMixin, NotificationMixin, AddonMixin],
    components: {
      InputEditor
    },
    props: {
      values: {
        type: Object,
        required: true
      },
      errors: {
        type: Object,
        default: () => { }
      },
      initialValues: {
        type: Object,
        default: () => { }
      },
      loading: {
        type: Boolean,
        required: true
      },
      parent: {
        type: Object,
        required: false
      },
      refForm: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        title: null,
        description: null,
        price: null,
        sortOrder: null,
      };
    },
    computed: {
      item() {
        return this.initialValues || this.values;
      },
      //region validation:
      titleErrors() {
        const errors = [];

        if (!this.$v.item.title.$dirty) {
          return errors;
        }

        has(this.violations, 'title') && errors.push(this.violations.title);

        !this.$v.item.title.required && errors.push(this.$t('Field is required'));

        return errors;
      },
      descriptionErrors() {
        const errors = [];

        if (!this.$v.item.description.$dirty) {
          return errors;
        }

        has(this.violations, 'description') && errors.push(this.violations.description);
        return errors;
      },
      priceErrors() {
        const errors = [];

        if (!this.$v.item.price.$dirty) {
          return errors;
        }

        has(this.violations, 'price') && errors.push(this.violations.price);
        return errors;
      },
      sortOrderErrors() {
        const errors = [];

        if (!this.$v.item.sortOrder.$dirty) {
          return errors;
        }

        has(this.violations, 'sortOrder') && errors.push(this.violations.sortOrder);
        return errors;
      }
    },
    validations: {
      item: {
        title: {
          required
        },
        description: {
          required
        },
        price: {
          required
        },
        sortOrder: {
        }
      }
    },
    mounted() {
      this.seminarGetSelectItems();
    },
    methods: {
      resolveParents(parent, path = []) {
        if (typeof parent == 'string') {
          parent = this.resolveAddon(parent);
        }
        path.push(parent.title ? { title: parent.title, '@id': parent['@id'] } : '');

        return parent.parent ? this.resolveParents(parent.parent, path) : path.reverse();
      },
      ...mapActions({
        seminarGetSelectItems: 'seminar/fetchSelectItems'
      }),
      updateDiscountsHandler(updatedDiscounts) {
        this.item.discounts = updatedDiscounts;
      }
    }
  };
</script>
